import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import ContactForm from "../../../../COMPONENTS/FORM/CONTACT";
import FAQ from "../../../../COMPONENTS/COMMON/FAQ";

const ContactSection = () => {
  return (
    <div className="contact-section">
      <Container fluid>
        {" "}
        <Row>
          {" "}
          {/* Set height of the row */}
          <Col xs={12} md={6} className="leftContact">
            <FAQ />
          </Col>
          <Col xs={12} md={6} className="rightContact">
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactSection;
