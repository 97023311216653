// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  /* padding: 2em; */
  background-color: #1c2025;
  color: #fff;
}

.footerLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.footer-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.footer li {
  text-decoration: none;
  margin: 0.5em 0;
}

.social-icons {
  display: flex;
  align-items: center; /* Center icons vertically */
  justify-content: center; /* Center icons horizontally */
  gap: 2em; /* Space between icons */
  height: 100%; /* Make the container take full height */
}

.social-icons a {
  color: #fff;
  text-decoration: none;
}

.social-icons img {
  width: 30px; /* Set a fixed width for icons */
  height: 30px; /* Set a fixed height for icons */
}

.footer-col {
  display: flex;
  align-items: center; /* Center items horizontally */
  justify-content: center;
  padding-bottom: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/COMPONENTS/FOOTER/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB,EAAE,4BAA4B;EACjD,uBAAuB,EAAE,8BAA8B;EACvD,QAAQ,EAAE,wBAAwB;EAClC,YAAY,EAAE,wCAAwC;AACxD;;AAEA;EACE,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,WAAW,EAAE,gCAAgC;EAC7C,YAAY,EAAE,iCAAiC;AACjD;;AAEA;EACE,aAAa;EACb,mBAAmB,EAAE,8BAA8B;EACnD,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".footer {\n  /* padding: 2em; */\n  background-color: #1c2025;\n  color: #fff;\n}\n\n.footerLogo {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 60%;\n}\n\n.logoContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 1em;\n}\n\n.footer-list {\n  list-style-type: none;\n  padding-left: 0;\n  margin: 0;\n}\n\n.footer li {\n  text-decoration: none;\n  margin: 0.5em 0;\n}\n\n.social-icons {\n  display: flex;\n  align-items: center; /* Center icons vertically */\n  justify-content: center; /* Center icons horizontally */\n  gap: 2em; /* Space between icons */\n  height: 100%; /* Make the container take full height */\n}\n\n.social-icons a {\n  color: #fff;\n  text-decoration: none;\n}\n\n.social-icons img {\n  width: 30px; /* Set a fixed width for icons */\n  height: 30px; /* Set a fixed height for icons */\n}\n\n.footer-col {\n  display: flex;\n  align-items: center; /* Center items horizontally */\n  justify-content: center;\n  padding-bottom: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
