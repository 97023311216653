import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";

import Logo from "../../../../ASSETS/2 Vex Logo PNG.png";
import petriDishImage from "../../../../ASSETS/2 Vex Petri Dish Image.webp";

const HeroSection = () => {
  return (
    <div className="hero-section">
      {/* <div className="overlay" /> */}
      <img src={Logo} className="vexillum-logo" alt="Vexillum Logo" />
      <Container>
        <Row>
          <Col xs={12} md={6} className="heroContent">
            Welcome to Vexillum Biospecimen Solutions, your trusted partner for
            comprehensive biospecimen collection services. We specialize in
            custom collection projects designed to meet the unique needs of
            Diagnostic, MedTech, Biotech, and Pharmaceutical companies. With a
            geographically diverse network of collection centers, we provide
            direct access to the patient populations of interest.
            <br />
            By purchasing consented samples directly from Vexillum, our industry
            partners can take advantage of expedited start-up timelines. All of
            our samples are collected by trained research staff under
            IRB-approved protocols, ensuring high-quality and ethically sourced
            specimens. Let us streamline your research and accelerate your
            success.
          </Col>
          <Col xs={12} md={6} className="">
            <img
              src={petriDishImage}
              className="petriDishImage"
              alt="Petri Dish"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
