import React from "react";
import "./index.css";
import HeroSection from "./SECTIONS/HERO";
import ContactSection from "./SECTIONS/CONTACT";
import CalendarSection from "./SECTIONS/CALENDAR";

const HomePage = () => {
  return (
    <>
      <div className="home">
        {" "}
        <HeroSection />
        <CalendarSection />
        <ContactSection />
      </div>
    </>
  );
};

export default HomePage;
