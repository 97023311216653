// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactForm {
  color: #aab5b7;
  margin-bottom: 0.5em;
}
.contacttitle {
  text-align: center;
}

.contactForm .form-control {
  line-height: 2em;
  width: 100%;
  border-radius: 1.5em;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.contactForm .form-control:focus {
  outline: none;
  border-color: #aab5b7;
}

.contactForm .form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contactForm .text-danger {
  color: #aab5b7 !important;
}

.formSubmit {
  padding-left: 4em;
  padding-right: 4em;
  background-color: rgb(79, 81, 79) !important;
  border-color: rgb(79, 81, 79) !important;
  border-radius: 2em;
  margin: 2px;
}
.contactForm .form-select {
  line-height: 2em;
  width: 100%;
  border-radius: 1.5em;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.contactForm .form-select:focus {
  border-color: #aab5b7;
  outline: none;
}

.contactForm .h2 {
  color: red;
}

.formSection {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  margin-left: 5em;
}

@media (max-width: 768px) {
  .formSection {
    margin-left: auto; /* Center the section */
    margin-right: auto; /* Center the section */
  }
}
`, "",{"version":3,"sources":["webpack://./src/COMPONENTS/FORM/CONTACT/index.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;AACtB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,oBAAoB;EACpB,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,4CAA4C;EAC5C,wCAAwC;EACxC,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,oBAAoB;EACpB,0CAA0C;AAC5C;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB,EAAE,2BAA2B;EACnD,gBAAgB;AAClB;;AAEA;EACE;IACE,iBAAiB,EAAE,uBAAuB;IAC1C,kBAAkB,EAAE,uBAAuB;EAC7C;AACF","sourcesContent":[".contactForm {\n  color: #aab5b7;\n  margin-bottom: 0.5em;\n}\n.contacttitle {\n  text-align: center;\n}\n\n.contactForm .form-control {\n  line-height: 2em;\n  width: 100%;\n  border-radius: 1.5em;\n  border: 1px solid rgba(255, 255, 255, 0.5);\n}\n\n.contactForm .form-control:focus {\n  outline: none;\n  border-color: #aab5b7;\n}\n\n.contactForm .form-control::placeholder {\n  color: rgba(255, 255, 255, 0.7);\n}\n\n.contactForm .text-danger {\n  color: #aab5b7 !important;\n}\n\n.formSubmit {\n  padding-left: 4em;\n  padding-right: 4em;\n  background-color: rgb(79, 81, 79) !important;\n  border-color: rgb(79, 81, 79) !important;\n  border-radius: 2em;\n  margin: 2px;\n}\n.contactForm .form-select {\n  line-height: 2em;\n  width: 100%;\n  border-radius: 1.5em;\n  border: 1px solid rgba(255, 255, 255, 0.5);\n}\n\n.contactForm .form-select:focus {\n  border-color: #aab5b7;\n  outline: none;\n}\n\n.contactForm .h2 {\n  color: red;\n}\n\n.formSection {\n  display: flex;\n  flex-direction: column; /* Stack items vertically */\n  margin-left: 5em;\n}\n\n@media (max-width: 768px) {\n  .formSection {\n    margin-left: auto; /* Center the section */\n    margin-right: auto; /* Center the section */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
