import Footer from "../COMPONENTS/FOOTER";
import NavBar from "../COMPONENTS/NAVBAR";
import "./index.css";

const Layout = ({ children }) => {
  return (
    <div className="layout">
      {/* <NavBar className="navbar" /> */}
      <div className="content">{children}</div>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
