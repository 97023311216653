import { useState, useEffect, useRef } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import "./index.css";
import NavbarLogo from "../../ASSETS/vexillum logo.png";

const NavBar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle dropdown visibility
  const handleToggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  // Close dropdown on click outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Navbar expand="lg" className="1000px">
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            src={NavbarLogo}
            alt="Vexillum BioSpecimens Logo"
            className="navbarLogo"
          />
          <span className="navbarText"> VEXILLUM BIOSPECIMEN SOLUTIONS</span>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default NavBar;
