import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "./index.css";
import CustomCard from "../CARD";

const FAQ = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const faqs = [
    {
      question: "What services does Vexillum Biospecimen Solutions offer?",
      answer: `Vexillum Biospecimen Solutions provides a complete suite of biospecimen
       collection and management services for diagnostic, MedTech, biotech, and 
       pharmaceutical companies. Our end-to-end offerings include sample collection, 
       laboratory testing, sample handling, shipping, and customized data reporting. 
       Our services are designed to support timely and high-quality sample acquisition 
       for critical research projects, leveraging both internal and external partnerships 
       for small or large-scale and specialized collections.`,
    },
    {
      question: "What types of biospecimens do you specialize in?",
      answer: `Vexillum specializes in a wide range of biospecimen collections across 
      multiple therapeutic areas, meeting diverse research needs. Our expertise includes 
      all types of respiratory biospecimens, infectious diseases, oncology, seasonal 
      conditions, autoimmune diseases, metabolic disorders, neurological conditions, 
      cardiovascular diseases and healthy/control specimens, among others. This variety 
      enables us to provide targeted, high-quality samples that support critical advancements 
      in diagnostics, preventive medicine and treatment development.`,
    },
    {
      question:
        "Does Vexillum provide support for protocol development and the IRB submission process?",
      answer: `Yes, Vexillum offers customized protocol development tailored to each project's needs. 
      Our team works closely with researchers to build protocols that align with specific study 
      objectives. Additionally, Vexillum supports the regulatory submission (IRB or Ethics Committee 
      as applicable) and approval process, ensuring compliance with ethical and regulatory standards 
      to streamline project initiation.`,
    },
    {
      question:
        "How does Vexillum ensure the quality and ethical collection of biospecimens?",
      answer: `Vexillum follows IRB-approved protocols and consent processes to ensure ethical 
      standards and regulatory compliance. We maintain strict quality control through Standard 
      Operating Procedures (SOPs), continuous sample monitoring, and regular audits. Our 
      approach guarantees the integrity, reliability, and compliance of all biospecimen collections.`,
    },
    {
      question: "Can Vexillum provide customized biospecimen collections?",
      answer: `Yes, Vexillum offers customized biospecimen collections tailored to specific 
      project needs. Our pre-configured solutions can be adapted to meet unique research 
      requirements, with flexible timelines and targeted collection based on geography, 
      demographics, or disease conditions.`,
    },
    {
      question: "What advantages do your banked samples provide?",
      answer: `Our bio banked samples offer researchers access to high-quality, 
      well-documented specimens that are readily available for urgent studies. By maintaining 
      diverse biospecimen collections, we support research into various disease states, providing 
      flexibility and rapid access to valuable data.`,
    },
    {
      question: "Where are your collection centers located?",
      answer: `Vexillum operates through a network of geographically diverse collection 
      centers, with primary locations across the U.S. and partnerships in the African 
      continent, parts of Europe, and South Asia. With access to both the Northern and 
      Southern Hemispheres, our network enables us to source seasonal as well as unique 
      biospecimen collections across various regions, enhancing our ability to meet diverse 
      research needs globally.`,
    },
    {
      question: "What is the process for accessing biospecimens from Vexillum?",
      answer: `To access biospecimens, researchers can contact our team to discuss project 
      specifics, after which we align collections and data management with their requirements. 
      We follow a streamlined protocol, ensuring timely sample delivery, comprehensive reporting, 
      and regulatory compliance at every step.`,
    },
  ];

  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  return (
    <Container className="faq-section p-4">
      <h2>Frequently Asked Questions</h2>
      {faqs.map((faq, index) => (
        <div key={index}>
          {" "}
          {/* Moved key here */}
          <CustomCard
            question={faq.question}
            answer={faq.answer}
            isActive={activeQuestion === index}
            onClick={() => toggleQuestion(index)}
          />
        </div>
      ))}
    </Container>
  );
};

export default FAQ;
