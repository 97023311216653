// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../../../ASSETS/COREBLUE.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./../../../../ASSETS/KURlinebg.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./../../../../ASSETS/KUR-linebg.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-section {
  color: #ffffff;
}

.text-container {
  text-align: left; /* Align text to the left */
  background-color: rgba(0, 0, 0, 0); /* Optional: add a dark overlay */
  border-radius: 8px; /* Optional: round the corners */
}

.contact_title {
  font-size: 4rem;
  color: #ffffff;
  border-bottom: 1px solid yellow;
  display: inline-block; /* Make the title's border wrap around the text */
  padding-bottom: 10px; /* Optional: Add some space between the text and the border */
}

.subtext {
  color: #aab5b7;
  line-height: 1.5em;
}

.location_highlight {
  color: yellow;
  border-bottom: 1px solid yellow;
}

.leftContact {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
}

.rightContact {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-size: 110%;
  background-position: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact_title {
    font-size: 3rem;
  }
  .rightContact {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
    background-size: 180%;
    background-position: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/HOME/SECTIONS/CONTACT/index.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB,EAAE,2BAA2B;EAC7C,kCAAkC,EAAE,iCAAiC;EACrE,kBAAkB,EAAE,gCAAgC;AACtD;;AAEA;EACE,eAAe;EACf,cAAc;EACd,+BAA+B;EAC/B,qBAAqB,EAAE,iDAAiD;EACxE,oBAAoB,EAAE,6DAA6D;AACrF;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,+BAA+B;AACjC;;AAEA;EACE,yDAA2D;EAC3D,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,yDAA4D;EAC5D,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA,2BAA2B;AAC3B;EACE;IACE,eAAe;EACjB;EACA;IACE,yDAA6D;IAC7D,qBAAqB;IACrB,2BAA2B;EAC7B;AACF","sourcesContent":[".contact-section {\n  color: #ffffff;\n}\n\n.text-container {\n  text-align: left; /* Align text to the left */\n  background-color: rgba(0, 0, 0, 0); /* Optional: add a dark overlay */\n  border-radius: 8px; /* Optional: round the corners */\n}\n\n.contact_title {\n  font-size: 4rem;\n  color: #ffffff;\n  border-bottom: 1px solid yellow;\n  display: inline-block; /* Make the title's border wrap around the text */\n  padding-bottom: 10px; /* Optional: Add some space between the text and the border */\n}\n\n.subtext {\n  color: #aab5b7;\n  line-height: 1.5em;\n}\n\n.location_highlight {\n  color: yellow;\n  border-bottom: 1px solid yellow;\n}\n\n.leftContact {\n  background-image: url(\"./../../../../ASSETS/COREBLUE.webp\");\n  background-size: cover;\n  background-position: center;\n}\n\n.rightContact {\n  background-image: url(\"./../../../../ASSETS/KURlinebg.webp\");\n  background-size: 110%;\n  background-position: center;\n}\n\n/* Responsive adjustments */\n@media (max-width: 768px) {\n  .contact_title {\n    font-size: 3rem;\n  }\n  .rightContact {\n    background-image: url(\"./../../../../ASSETS/KUR-linebg.webp\");\n    background-size: 180%;\n    background-position: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
