import React from "react";
import { Container, Row } from "react-bootstrap";
import "./index.css";

const CalendarSection = () => {
  return (
    <div className="calendarSection">
      <Container fluid className="h-100">
        <Row className="justify-content-center align-items-center h-100">
          <p className="calendarContentSection">
            If you're interested in learning more about our services or have
            specific requests, please{" "}
            <a
              href="https://calendly.com/alimongi-kurr/vexillum-biospecimen-solutions-introductory-meeting"
              target="_blank"
              rel="noreferrer"
            >
              <strong>book a consultation</strong>{" "}
            </a>
            with us or fill out the form below.
          </p>
        </Row>
      </Container>
    </div>
  );
};

export default CalendarSection;
