// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendarSection {
  background-color: rgb(62, 82, 81);
  height: 50vh;
  padding: auto;
}

.calendarContentSection {
  color: white;
  font-size: 2rem; /* Responsive font size */
  font-family: "sans-serif";
  text-align: center; /* Center text */
  width: 25em !important;
  line-height: 1.5; /* Add line height for better readability */
}

.calendarContentSection a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 800;
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/HOME/SECTIONS/CALENDAR/index.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,eAAe,EAAE,yBAAyB;EAC1C,yBAAyB;EACzB,kBAAkB,EAAE,gBAAgB;EACpC,sBAAsB;EACtB,gBAAgB,EAAE,2CAA2C;AAC/D;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".calendarSection {\n  background-color: rgb(62, 82, 81);\n  height: 50vh;\n  padding: auto;\n}\n\n.calendarContentSection {\n  color: white;\n  font-size: 2rem; /* Responsive font size */\n  font-family: \"sans-serif\";\n  text-align: center; /* Center text */\n  width: 25em !important;\n  line-height: 1.5; /* Add line height for better readability */\n}\n\n.calendarContentSection a {\n  text-decoration: none;\n  color: #ffffff;\n  font-weight: 800;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
