import { Col, Container, Row } from "react-bootstrap";
import "./index.css";
import footerLogo from "../../ASSETS/image (3).webp";

import linkedInLogo from "../../ASSETS/linkedin-3.svg";
import facebookLogo from "../../ASSETS/facebook.svg";

const Footer = () => {
  return (
    <Container fluid>
      <Row className="footer">
        <Col xs={12} md={4} className="footer-col">
          <div className="logoContainer">
            <img
              src={footerLogo}
              className="footerLogo"
              alt="Bio-Specimen Solutions"
            />
          </div>
        </Col>
        {/* <Col xs={12} md={3} className="footer-col">
          <ul className="footer-list">
            <li>About Kur</li>
            <li>Mission Statement</li>
            <li>Executive Team</li>
            <li>Job Application</li>
          </ul>
        </Col> */}
        <Col xs={12} md={4} className="footer-col">
          <div>
            <span>
              <strong>Contact</strong>
            </span>
            <ul className="footer-list">
              <li>(866) 300-5380</li>
              <li>vexillum@kurr.com</li>
              <li>2452 Cedar Springs Rd</li>
              <li>Dallas, Texas 75201</li>
            </ul>
          </div>
        </Col>
        <Col xs={12} md={4} className="footer-col">
          <div className="social-icons">
            <a
              href="https://www.linkedin.com/company/vexillum-llc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedInLogo} alt="LinkedIn" />
            </a>
            <a
              href="https://www.facebook.com/people/Vexillum-LLC/61562127970179"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebookLogo} alt="Facebook" />
            </a>
            {/* <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instaLogo} alt="Instagram" />
            </a>
            <a
              href="https://www.tiktok.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tiktokLogo} alt="TikTok" />
            </a> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
