import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Form, Container, Button, Toast, Spinner } from "react-bootstrap";
import axios from "axios";
import "./index.css";
import { api } from "../../../CONSTANTS";

// Validation schema using Yup
const schema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required"),
  contactName: Yup.string().required("Contact name is required"),
  phone: Yup.string(),
  email: Yup.string().email("Invalid email").required("Email is required"),
  quantity: Yup.number().typeError("Quantity must be a number"),
  date: Yup.date(),
  indication: Yup.string(),
  specimenType: Yup.string(),
  media: Yup.string(),
  storageTemp: Yup.string(),
  comments: Yup.string(),
});

// Dropdown options
const options = {
  indication: [
    { value: "", label: "Select indication" },
    { value: "Healthy/Negative", label: "Healthy/Negative" },
    { value: "Influenza A", label: "Influenza A" },
    { value: "Influenza B", label: "Influenza B" },
    { value: "RSV", label: "RSV" },
    { value: "SARS-CoV-2", label: "SARS-CoV-2" },
    { value: "Strep", label: "Strep" },
    { value: "CT/NG", label: "CT/NG" },
    { value: "HPV, HSV, HZV", label: "HPV, HSV, HZV" },
    { value: "Oncology", label: "Oncology" },
    { value: "Other", label: "Other" },
  ],
  specimenType: [
    { value: "", label: "Select specimen type" },
    { value: "Anterior Nasal Swab", label: "Anterior Nasal Swab" },
    { value: "Nasopharyngeal Swab", label: "Nasopharyngeal Swab" },
    { value: "Mid-Turbinate Nasal Swab", label: "Mid-Turbinate Nasal Swab" },
    { value: "Oropharyngeal Swab", label: "Oropharyngeal Swab" },
    { value: "Oral Fluid", label: "Oral Fluid" },
    { value: "Buccal Scrape", label: "Buccal Scrape" },
    { value: "Hair", label: "Hair" },
    { value: "Vaginal Swab", label: "Vaginal Swab" },
    { value: "Penile Swab", label: "Penile Swab" },
    { value: "Rectal Swab", label: "Rectal Swab" },
    { value: "Urine Sample", label: "Urine Sample" },
    { value: "Stool Sample", label: "Stool Sample" },
    { value: "Venous Blood Sample", label: "Venous Blood Sample" },
    { value: "Peripheral Blood Sample", label: "Peripheral Blood Sample" },
    { value: "Serum", label: "Serum" },
    { value: "Plasma", label: "Plasma" },
    { value: "PBMCs", label: "PBMCs" },
    { value: "Other", label: "Other" },
  ],
  media: [
    { value: "", label: "Select media" },
    { value: "UTM/VTM/Saline", label: "UTM/VTM/Saline" },
    { value: "Dry", label: "Dry" },
  ],
};

// Form fields configuration
const formFields = [
  { name: "companyName", label: "Company Name", type: "text", required: true },
  { name: "contactName", label: "Contact Name", type: "text", required: true },
  { name: "phone", label: "Phone Number", type: "tel", required: false },
  { name: "email", label: "Email Address", type: "email", required: true },
  {
    name: "quantity",
    label: "Quantity Needed",
    type: "number",
    required: false,
  },
  { name: "date", label: "Date Samples Needed", type: "date", required: false },
  {
    name: "storageTemp",
    label: "Storage Temperature",
    type: "text",
    required: false,
  },
];

const ContactForm = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoading(true); // Start loading
    try {
      await axios.post(api, data);
      setToastMessage("Form submitted successfully!");
      setToastVariant("success");
      reset(); // Reset the form fields
    } catch (error) {
      setToastMessage("Error submitting form. Please try again.");
      setToastVariant("danger");
    } finally {
      setShowToast(true);
      setLoading(false); // Stop loading
    }
  };

  return (
    <Container className="contactForm p-5">
      <h2 className="contacttitle">Contact Us</h2>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="formSection"
      >
        {formFields.map(({ name, label, type, required }) => (
          <Form.Group controlId={`form${name}`} key={name}>
            <Form.Label>
              {label} {required && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Control
              type={type}
              {...register(name)}
              isInvalid={!!errors[name]}
            />
            <Form.Control.Feedback type="invalid">
              {errors[name]?.message}
            </Form.Control.Feedback>
          </Form.Group>
        ))}

        {/* Indication of Samples */}
        <Form.Group controlId="formIndication">
          <Form.Label>Indication of Samples</Form.Label>
          <Form.Select
            {...register("indication")}
            isInvalid={!!errors.indication}
          >
            {options.indication.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.indication?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Specimen Type */}
        <Form.Group controlId="formSpecimenType">
          <Form.Label>Specimen Type</Form.Label>
          <Form.Select
            {...register("specimenType")}
            isInvalid={!!errors.specimenType}
          >
            {options.specimenType.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.specimenType?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Media Used for Storage */}
        <Form.Group controlId="formMedia">
          <Form.Label>Media Used for Storage</Form.Label>
          <Form.Select {...register("media")} isInvalid={!!errors.media}>
            {options.media.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.media?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Comments Field at the Bottom */}
        <Form.Group controlId="formComments">
          <Form.Label>Comments</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            {...register("comments")}
            isInvalid={!!errors.comments}
          />
          <Form.Control.Feedback type="invalid">
            {errors.comments?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <div className="d-flex justify-content-center mt-4 contactButton">
            <Button
              type="submit"
              disabled={loading} // Disable button while loading
              style={{
                width: "auto",
                backgroundColor: "white",
                color: "black",
                border: "1px solid #007bff",
                borderRadius: "2em",
                transition: "background-color 0.3s, border-color 0.3s",
              }}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </Button>
          </div>
        </Form.Group>
      </Form>

      {/* Toast Notification */}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        className={`bg-${toastVariant} text-white position-fixed`}
        style={{ bottom: "20px", right: "20px" }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </Container>
  );
};

export default ContactForm;
